const menuOptions = {
  STORE: [
    {
      title: 'globals.changeProvidersByCountryUser',
      key: 'SHOW_ONLY_PROVIDERS_BY_COUNTRY',
      url: '',
      icon: 'lnr lnr-sync',
    },
    {
      title: 'globals.changeProvider',
      key: 'CHANGE_CITY',
      url: '',
      icon: 'lnr lnr-sync',
    },
    {
      title: 'globals.changetype',
      key: 'CHANGE_TYPE',
      url: '',
      icon: 'lnr lnr-sync',
    },
    {
      title: 'orders.order',
      key: 'ORDERS_SECTION',
      icon: 'lnr lnr-list',
      children: [
        {
          title: 'orders.order',
          key: 'ORDERS_SECTION',
          url: '/admin/orders',
        }
      ],
    },
    {
      title: 'globals.changeOrderStatus',
      key: 'CHANGE_ORDER_STATUS',
      url: '',
      icon: 'lnr lnr-sync',
    },
    {
      title: 'orders.allowCancelOrders',
      key: 'ALLOW_CANCEL_ORDERS',
      url: '',
      icon: 'lnr lnr-sync',
    },
    {
      title: 'orders.seeOrdersAllProviders',
      key: 'ORDER_ALL_PROVIDERS',
      url: '',
      icon: 'lnr lnr-sync',
    },

    {
      title: 'registrationPurchaseOrders.title',
      key: 'PURCHASE_ORDERS_SECTION',
      url: '/admin/listPurchaseOrders',
      icon: 'lnr lnr-briefcase',
    },
    {
      title: 'registrationPurchaseOrders.approvePurchaseOrders',
      key: 'APPROVE_PURCHASE_ORDERS',
      url: '',
      icon: 'lnr lnr-sync',
    },
    // {
    //   title: 'registrationPurchaseOrders.approvePurchaseOrdersAllCities',
    //   key: 'APPROVE_PURCHASE_ORDERS_ALL_CITIES',
    //   url: '',
    //   icon: 'lnr lnr-sync',
    // },

    {
      title: 'givepointsOrders.title',
      key: 'GIVE_POINS_ORDERS_SECTION',
      url: '/admin/givePointsOrders',
      icon: 'lnr lnr-diamond',
    },
    {
      title: 'promotions.title',
      key: 'PROMOTION_SECTION',
      url: '/admin/promotions/store',
      icon: 'lnr lnr-tag',
    },
    {
      title: 'products.product',
      key: 'PRODUCTS_SECTION',
      url: '/admin/productsCards/ok/1',
      icon: 'lnr lnr-cart',
    },
    {
      title: 'Products.tax',
      key: 'PRODUCTS_TAXES_SECTION',
      url: '/admin/productsTaxes',
      icon: 'lnr lnr-book',
    },
    {
      title: 'users.user',
      key: 'USERS_SECTION',
      icon: 'lnr lnr-users',
      url: '/admin/Users',
    },
    {
      title: 'users.whitelist',
      key: 'USERS_WHITE_LIST',
      icon: 'lnr lnr-users',
      url: '',
    },
    {
      title: 'users.coordinator',
      key: 'USERS_COORDINATOR',
      icon: 'lnr lnr-users',
      url: '',
    },
    {
      title: 'users.inheritedProvidersUsers',
      key: 'INHERITED_PROVIDERS_USERS',
      icon: 'lnr lnr-users',
      url: '',
    },
    {
      title: 'salesman.title',
      key: 'SALESMAN_SECTION',
      icon: 'lnr lnr-thumbs-up',
      children: [
        {
          title: 'trackingComercial.tracking',
          key: 'tracking',
          url: '/admin/salesmanTracking',
        },
        {
          title: 'dailyCommitment.title',
          key: 'dailyCommitment',
          url: '/admin/dailyCommitment',
        },
        {
          title: 'salesman.VisitToStores',
          key: 'VisitToStores',
          url: '/admin/visitProcess',
        },
      ],
    },
    {
      title: 'Notifications.title',
      key: 'NOTIFICATIONS_SECTION',
      url: '/admin/notificationsList',
      icon: 'lnr lnr-bullhorn',
    },
    {
      title: 'campaigns.title',
      key: 'CAMPAIGNS_SECTION',
      url: '/admin/campaigns',
      icon: 'lnr lnr-rocket',
    },
    {
      title: 'coupons.title',
      key: 'COUPONS_SECTION',
      url: '/admin/coupons',
      icon: 'lnr lnr-star',
    },
    {
      title: 'appComparative.title',
      key: 'APP_COMPARATIVE_SECTION',
      url: '/admin/appComparative',
      icon: 'lnr lnr-cloud-upload',
    },
    {
      title: 'Missions.mission',
      key: 'MISSIONS_SECTION',
      url: '/admin/missionsTable',
      icon: 'lnr lnr-flag',
      children: [
        {
          title: 'Missions.missionDashboard',
          key: 'missionsDashboard',
          url: '/admin/missionsDashboard',
        },
        {
          title: 'Missions.mission',
          key: 'missionsTable',
          url: '/admin/missionsTable',
        },
      ],
    },
    {
      title: 'ads.title',
      key: 'HOME_ADS_SECTION',
      url: '/admin/ads',
      icon: 'lnr lnr-smartphone',
    },
    {
      title: 'baskets.name',
      key: 'BASKETS_SECTION',
      url: '/admin/baskets',
      icon: 'lnr lnr-layers',
    },
    {
      title: 'baskets.permissionsCreate',
      key: 'CREATE_BASKETS',
      url: '',
      icon: 'lnr lnr-plus',
    },
    {
      title: 'Inventory.title',
      key: 'INVENTORY_SECTION',
      icon: 'lnr lnr-database',
      children: [
        {
          title: 'Inventory.ListInventory',
          key: 'inventarioTable',
          url: '/admin/inventario',
        },
        /* {
         title: 'Inventory.uploadFile',
         key: 'UploadUp',
         url: '/admin/upload',
       }, */
        {
          title: 'Inventory.ReturnOrdersTitle',
          key: 'OrdersToReturnProducts',
          url: '/admin/OrdersToReturnProducts',
        },
        {
          title: 'inventoryprocess.title',
          key: 'inventoryProcess',
          url: '/admin/inventoryProcess',
        },
      ],
    },
    {
      title: 'Brands.Brand',
      key: 'BRANDS_SECTION',
      icon: 'lnr lnr-magic-wand',
      children: [
        {
          title: 'Brands.tittle',
          key: 'brandsTable',
          url: '/admin/brands',
        },
        {
          title: 'Inventory.uploadFile',
          key: 'UploadBrands',
          url: '/admin/UploadFileBrands',
        },
      ],
    },
    {
      title: 'globals.coverage',
      key: 'COVERAGE_SECTION',
      icon: 'lnr lnr-map-marker',
      children: [
        {
          title: 'countries.title',
          key: 'countries',
          url: '/admin/countries',
        },
        {
          title: 'cities.title',
          key: 'cities',
          url: '/admin/cities',
        },
        {
          title: 'Zones.zone',
          key: 'Zonas',
          url: '/admin/zones',
        },
        {
          title: 'localities.title',
          key: 'localities',
          url: '/admin/localities',
        },
        {
          title: 'neighboarhood.title',
          key: 'neighboarhood',
          url: '/admin/neighboarhood',
        },
        {
          title: 'zipCodes.title',
          key: 'zipCodes',
          url: '/admin/zipCodes',
        },
      ],
    },
    {
      title: 'providers.title',
      key: 'PROVIDERS_SECTION',
      url: '/admin/providers',
      icon: 'lnr lnr-mustache',
    },
    {
      title: 'retailers.title',
      key: 'RETAILERS_SECTION',
      url: '/admin/retailers',
      icon: 'lnr lnr-coffee-cup',
    },
    {
      title: 'holidays.holidays',
      key: 'HOLIDAYS_SECTION',
      url: '/admin/holidays',
      icon: 'lnr lnr-cross-circle',
    },
    {
      title: 'proccess.title',
      key: 'PICKER_PROCCESS_SECTION',
      url: '/admin/pickerProcess',
      icon: 'icmn icmn-clipboard',
    },
    {
      title: 'supplies.supplies',
      key: 'SUPPLIES_SECTION',
      url: '/admin/supplies',
      icon: 'lnr lnr-inbox',
    },
    {
      title: 'globals.mudules',
      key: 'PERMISSIONS_SECTION',
      url: '/admin/permissionsAdmin',
      icon: 'lnr lnr-code',
    },
    {
      title: 'no_page',
      key: 'NO_PAGE_SECTION',
      url: '/pages/noPage',
      icon: 'lnr lnr-code',
    }
  ],
  Q2C: [
    {
      title: 'orders.order',
      key: 'Q2C_ORDERS_SECTION',
      url: '/pages/dashboard/q2c/orders',
      icon: 'lnr lnr-list',
    },
    {
      title: 'promotions.title',
      key: 'Q2C_PROMOTION_SECTION',
      url: '/admin/promotions/q2c',
      icon: 'lnr lnr-tag',
    },
    {
      title: 'users.user',
      key: 'USERS_SECTION_Q2C',
      url: '/pages/dashboard/q2c/users',
      icon: 'lnr lnr-users',
    },
    {
      title: 'salesman.stores',
      key: 'Q2C_STORES_SECTION',
      url: '/pages/dashboard/q2c/stores',
      icon: 'lnr lnr-home',
    },
    {
      title: 'products.product',
      key: 'Q2C_PRODUCTS_SECTION',
      url: '/pages/dashboard/q2c/products',
      icon: 'lnr lnr-cart',
    },
    {
      title: 'catalogUpdate.cataUpdate2',
      key: 'UPDATE_CATALOGUE_Q2C',
      url: '/pages/dashboard/q2c/uploadFile',
      icon: 'lnr lnr-paperclip',
    },
  ],
}

export default menuOptions
